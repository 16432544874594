import React from "react"
import { graphql, Link } from "gatsby"

// components
import TopElement from "../../components/topElement"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TopElementCenter from "../../components/topElementCenter"

import RefElement from "../../components/refElement"

import Tabs from "../../components/Tabs"
import ImmobilienList from "../../components/ImmobilienListMieten"
import AktuelleProjekteList from "../../components/ImmobilienListProjektAktuell"
import "../../assets/css/referenzen.css"

const furtherRefList = [
  {name: "Islergasse 10", place: "8703 Erlenbach"},
  {name: "Neues Schloss Privat Hotel", place: "8002 Zürich"},
  {name: "Eierbrechtstrasse 24", place: "8053 Zürich"},
  {name: "Regensbergstrasse 123", place: "8050 Zürich"}, 
  {name: "Goldbrunnerstrasse 137", place: "8055 Zürich"},
  {name: "Hohlstrasse 27", place: "8004 Zürich"}
]

const ImmobilienPage = ({ data }) => {
  return (
    <Layout
      initialBackgroundColor="bg-white"
      headerTitle="Unsere Immobilien"
      headerSub="Immer mit Stil, immer individuell"
      pageNav="immo"
      bgImg="immobilienReferenzen"
    >
      <Seo title="Handwerk aus Leidenschaft" />

      <TopElement text="Die Hossmann Immobilien AG entwickelt und verwaltet hochwertigen Geschäfts- und Wohnraum für das eigene Liegenschaften-Kernportfolio, als auch Objekte im Stockwerkeigentum. Auf Qualität und attraktive Grundrisse wird dabei stets grossen Wert gelegt." />

      <div className="grid max-w-screen-lg gap-16 mx-auto mb-24 md:gap-48 md:mt-12">
        <Tabs>
          {/*
          <div label="Mieten" id="panel-1" role="tabpanel">
            <section className="px-4 py-4 md:px-8 lg:px-16 xl:px-0 md:py-12 lg:py-24">
              <div className="hidden pb-12 text-center">
                <div>
                  <h3 className="pb-2 text-secondary">
                    Ist auch Ihr neues Zuhause dabei?
                  </h3>
                  <div className="mb-6 text-4xl h1">
                    <span className="text-gray-800">
                      Vielfalt in Graubünden und Zürich
                    </span>
                    .
                  </div>
                </div>
              </div>

              <ImmobilienList />
            </section>
          </div>*/}

          <div label="aktuelle Projekte" id="panel-2" role="tabpanel">
            <section className="px-4 py-4 md:px-8 lg:px-16 xl:px-0 md:py-12 lg:py-24">
              <div className="hidden pb-12 text-center">
                <div>
                  <h3 className="pb-2 text-secondary">
                    Immer mit Stil, immer individuell
                  </h3>
                  <div className="mb-6 text-4xl h1">
                    <span className="text-gray-800">
                      Entwicklung hochwertiger Immobilien
                    </span>
                    .
                  </div>
                </div>
                {data.ProjekteAktuell.totalCount >= 1 && (
                  <Link className="self-center" to="/immobilien/immobilien/">
                    <span className="btn btn-small btn-outline">
                      alle aktuellen Projekte
                    </span>
                  </Link>
                )}
              </div>
              <AktuelleProjekteList />
            </section>
          </div>

{/*           <div label="aktuelle Projekte" id="panel-2" role="tabpanel">
            <section className="px-4 py-4 md:px-8 lg:px-16 xl:px-0 md:py-12 lg:py-24">

              <div className="grid max-w-screen-lg grid-cols-6 gap-8 pt-0 mx-auto 2xl:max-w-screen-xl">
                <RefElement
                  projektName="Chur"
                  refNr="10"
                  caption="Brinerstrasse 2, Aussenansicht I"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Zürich"
                  refNr="9"
                  caption="Brinerstrasse 2, Aussenansicht I"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
              </div>

            </section>
          </div> */}

          <div label="Referenzen" id="panel-3" role="tabpanel">
            <section className="px-4 py-4 md:px-8 lg:px-16 xl:px-0 md:py-12 lg:py-24">
              <div className="hidden pb-12 text-center">
                <div>
                  <h3 className="pb-2 text-secondary">
                    Entwicklung für das eigene Portfolio &amp; Stockwerkeigentum
                  </h3>
                  <div className="mb-6 text-4xl h1">
                    <span className="text-gray-800">
                      Highlights aus unserer Entwicklung
                    </span>
                    .
                  </div>
                </div>
              </div>

              <div className="grid max-w-screen-lg grid-cols-6 gap-8 pt-0 mx-auto 2xl:max-w-screen-xl">
              <RefElement
                  projektName="Chur"
                  refNr="12"
                  caption="Mehrfamilienhaus, Chur"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Straja"
                  refNr="10"
                  caption="Wohnhaus Straja, Thusis"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Niederhasli"
                  refNr="9"
                  caption="Mehrfamilienhaus, Niederhasli"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Mountain Suites Flims"
                  refNr="6"
                  caption="Mountain Suites Flims"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Campagna Bonaduz"
                  refNr="7"
                  caption="Campagna Bonaduz"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Thusis"
                  refNr="8"
                  caption="Mehrfamilienhaus, Thusis"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
                <RefElement
                  projektName="Underm Rain"
                  refNr="11"
                  caption="Mehrfamilienhaus, Thusis"
                  className="col-span-6 md:col-span-3 lg:col-span-2 h-520"
                />
              </div>

              <TopElementCenter title="Weitere" />

              <div className="grid max-w-screen-lg grid-cols-1 gap-8 pt-0 mx-auto list-none sm:grid-cols-2 md:grid-cols-4 2xl:max-w-screen-xl">
                {furtherRefList.map((item) => (
                  <li key={item.name} className="col-span-1 p-6 text-center bg-gray-100">
                    <strong>{item.name}</strong>
                    <br />
                    {item.place}
                  </li>
                ))}
              </div>
            </section>
          </div>


        </Tabs>
      </div>
    </Layout>
  )
}

export default ImmobilienPage

export const pageQuery = graphql`
  {
    HeroPropertyReference: file(
      relativePath: { eq: "references/dufourstr-zuerich-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    ImmobilienVermieten: allMdx(
      filter: { fileAbsolutePath: { regex: "/immobilien(?!/sample)/" } }
    ) {
      totalCount
    }
    ProjekteAktuell: allMdx(
      filter: {
        frontmatter: { projektart: { eq: "aktuelles Projekt" } }
        fileAbsolutePath: { regex: "/projekte(?!/sample)/" }
      }
    ) {
      totalCount
    }
    EntwicklungPortfolio: allMdx(
      filter: {
        frontmatter: {
          referenzart: { eq: "Entwicklung für das eigene Portfolio" }
        }
        fileAbsolutePath: { regex: "/projekte(?!/sample)/" }
      }
    ) {
      totalCount
    }
    Stockwerkeigentum: allMdx(
      filter: {
        frontmatter: { referenzart: { eq: "Stockwerkeigentum" } }
        fileAbsolutePath: { regex: "/projekte(?!/sample)/" }
      }
    ) {
      totalCount
    }
    refImage1: file(relativePath: { eq: "references/ref-c-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    refImage2: file(relativePath: { eq: "references/ref-b-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    refImage3: file(relativePath: { eq: "references/ImageGen.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    refImage6: file(relativePath: { eq: "references/ImageGen.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    refImage4: file(relativePath: { eq: "references/ref-a-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    refImage5: file(
      relativePath: {
        eq: "references/carlton-restaurant-da-vittorio-st-moritz.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
`
