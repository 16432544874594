import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function AktuelleProjekteList() {
  const options = {
    caption: {
      captionFontFamily: "Lato",
      captionFontWeight: "300",
    }
  }

  const data = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {projektart: {eq: "aktuelles Projekt"}}, fileAbsolutePath: {regex: "/projekte(?!\/sample)/"}}) {
        edges {
          node {
            frontmatter {
              slug
              title
              kurzbeschreibung
              m
              ort
              plz
              zimmer
              bezug
              wohnungen
              featuredImage {
                childImageSharp {
                  gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
      galleries: allFile(filter: {sourceInstanceName: {eq: "gallery"}}) {
        edges {
          node {
            id
            relativeDirectory
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const posts = data.allMdx.edges
  const galleries = data.galleries.edges

  // Log galleries data for debugging
  console.log("Galleries Data:", galleries)

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 row-gap-16 md:grid-cols-3 lg:gap-6 xl:gap-8 lightBox">
        {!!posts.length && posts.map(({ node }) => {
          const { title, slug, kurzbeschreibung, m, zimmer, bezug, wohnungen, featuredImage } = node.frontmatter
          const featuredImg = getImage(featuredImage?.childImageSharp.gatsbyImageData)

          // Log the current project slug for debugging
          console.log(`Current project slug: ${slug}`)

          // Filter gallery images based on relative directory matching the project slug
          const projectGallery = galleries.filter(image => image.node.relativeDirectory === slug)

          // Log the projectGallery to check if images are found
          console.log(`Gallery for project ${title} (${slug}):`, projectGallery)

          return (
            <div key={slug} className="project-card group">
              <div className="w-full transition-all duration-300 transform group-hover:shadow-2md group-hover:-translate-y-2 cursor-pointer">
                {bezug && (
                  <p className="absolute top-0 left-0 z-10 px-2 py-1 text-xs text-white transition-all duration-300 transform bg-gray-800 group-hover:shadow-lg group-hover:-translate-y-2 group-hover:-translate-x-2 delay-50">
                    Bezug im {bezug}
                  </p>
                )}

                {projectGallery.length > 0 ? (
                  <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                      {featuredImg && <GatsbyImage image={featuredImg} alt={title} class="h-300" />}
                      {projectGallery.map(({ node: imageNode }) => {
                        const image = getImage(imageNode.childImageSharp.gatsbyImageData)
                        return (
                          <GatsbyImage 
                            key={imageNode.id} 
                            image={image} 
                            alt={`${title} Gallery Image`} 
                            className="gallery-image opacity-0 absolute h-0" 
                          />
                        )
                      })}
                    </SRLWrapper>
                  </SimpleReactLightbox>
                ) : (
                  <p className="text-center text-sm text-gray-500">No gallery images available</p>
                )}
                
              </div>

              <div className="mt-3">
                <h3 className="font-serif font-bold text-gray-900">{title}</h3>
                <h4 className="mb-2 text-sm">{kurzbeschreibung}</h4>

                <div className="grid grid-cols-4 py-2 mb-2 text-center md:py-3">
                  {zimmer && <div className="leading-4"><span className="text-sm font-semibold">{zimmer}</span><span className="block text-xs text-gray-600">Zimmer</span></div>}
                  {m && <div className="leading-4"><span className="text-sm font-semibold">{m}</span><span className="block text-xs text-gray-600">m²</span></div>}
                  {wohnungen && <div className="leading-4"><span className="text-sm font-semibold">{wohnungen}</span><span className="block text-xs text-gray-600">Wohnungen</span></div>}
                </div>

                {/* Conditional Buttons */}

                {slug === "brinerstr" && (
                  <a href="mailto:immobilien@hossmann.com" className="text-secondary font-semibold" target="_blank">
                    Mietanfrage senden &#8594;
                  </a>
                )}
              </div>
            </div>
          )
        })}
      </div>

      {!posts.length && <p className="text-center">Derzeit haben wir keine Objekte im Bau.</p>}
    </div>
  )
}

export default AktuelleProjekteList
